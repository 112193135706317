import React, { ReactNode, useEffect, useState } from "react";
import clsx from "clsx";
import { Typography } from "../../../Typography";

import { CourseInfoContainer } from "./CourseInfoStyled";
import useAuth from "../../../../context/user/hooks/useAuth";

export interface Props {
	name: string;
	description: string;
	truncateDescription?: boolean;
	favBtn?: ReactNode
}

const CourseInfoWrapper: React.FC<Props> = ({ ...props }: Props) => {
	const { name, description, truncateDescription, favBtn } = props;
	const { userData } = useAuth();
	const [udata, setudata] = useState({
		nome: "",
		email: "",
	})
	useEffect(() => {
		const targetDomain = 'tally.so';
		const queryParams = { ...udata };
	
		// Optional: Wait until iframe is rendered
		const interval = setInterval(() => {
		  const iframes = document.querySelectorAll('iframe');
			//@ts-ignore
		  for (const iframe of iframes) {
			try {
			  const url = new URL(iframe.src);
	
			  // Check if it's the domain we care about
			  if (url.hostname.includes(targetDomain)) {
				// Update query params (preserve others)
				Object.entries(queryParams).forEach(([key, value]) => {
				  url.searchParams.set(key, value);
				});
	
				// Only update if src has changed (avoid reload loops)
				const newSrc = url.toString();
				if (iframe.src !== newSrc) {
				  iframe.src = newSrc;
				}
	
				clearInterval(interval); // stop polling after success
				break;
			  }
			} catch (err) {
			  // ignore invalid iframe src
			}
		  }
		}, 300); // Poll every 300ms
	
		// Cleanup
		return () => clearInterval(interval);
	  }, [udata]);
	  useEffect(() => {
		if(udata.nome === "") {
			if(userData) {
				setudata({
					nome: userData.nome_aluno,
					email: userData.name
				})
			}
		}
	  }, [userData])
	return (
		<CourseInfoContainer {...props}>
			<Typography variant='title' size='18px' margin='0 0 21px 0' fontWeight='bold' display="flex"
			>
				{favBtn} {name}
			</Typography>
			<div
				className={clsx("descriptionWrapper", {
					truncateDescription: truncateDescription,
				})}>
				<Typography variant='text'>
					<div dangerouslySetInnerHTML={{ __html: description }} />
				</Typography>
			</div>
		</CourseInfoContainer>
	);
};

export { CourseInfoWrapper as CourseInfo };
