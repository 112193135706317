import styled from "styled-components";
import media from "../../../../styles/media";
import { Props } from "./index";

const CourseInfoContainer = styled.div<Props>`
	display: flex;
	flex-direction: column;
	//background: #fff;
	width: calc(100% - 350px);
	${media.lessThan("medium")`
    width: 100%;
    margin-bottom: 20px;
  `}
	.descriptionWrapper {
		//transition: 0.8s;
		max-height: 9000px;
		padding-bottom: 50px;
		&.truncateDescription {
			max-height: 400px;
			overflow: hidden;
			padding-bottom: 0px;
		}
	}
	.btn_zap_aula {
		display: inline-block;
		padding: 20px;
		border-radius: 5px;
		background: #25d366;
		color: white;
		font-weight: 700;
		text-decoration: none;
		transition: 0.3s;
		font-size: 1.125rem;
		text-transform: uppercase;
		&:hover {
			opacity: 0.7;
		}
	}
`;
export { CourseInfoContainer };
